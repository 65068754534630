import axios from "axios";
import '../api/axios';

export default {
    async logOut(context) {
        await localStorage.removeItem('token');
        await sessionStorage.removeItem('token');
        await context.commit('setToken', null)
        await context.commit('setUserInfo', null);
    },

    async login(context, data) {
        let response = await axios.post('login', data.login);

        if ( !response.data.ok ) {
            const error = new Error( response.message || 'Failed auth');
            throw error;
        }
        let token = response.data.token;
        axios.defaults.headers.common['token'] = token;

        await context.commit('setToken', token);
        if ( data.check ) {
            await localStorage.setItem('token', response.data.token);
        } else {
            await sessionStorage.setItem('token', response.data.token)
        }
        await context.commit('setUserInfo', response.data.user);

    },
    async signIn(context, data) {
        let response = await axios.post('createUser', data);

        if ( !response.data.ok ) {
            const error = new Error( response.message || 'Failed auth');
            throw error;
        }
        let token = response.data.token;
        axios.defaults.headers.common['token'] = token;

        await context.commit('setToken', token);

        await localStorage.setItem('token', response.data.token);

        await context.commit('setUserInfo', response.data.user);


    },
    async lessons(context) {

        let response = await axios.get('lesson');

        if ( !response.data.ok ) {
            const error = new Error( response.message || 'Failed auth');
            throw error;
        }
        await context.commit('setLessons', response.data.lessons);

    },
    async texts(context) {

        let response = await axios.get('text');

        if ( !response.data.ok ) {
            const error = new Error( response.message || 'Failed auth');
            throw error;
        }

        await context.commit('setTexts', response.data.texts);

    },
    async userInfo(context) {
        let response = await axios.get('verifyUser');
        if ( !response.data.ok ) {
            await localStorage.removeItem('token');
            await sessionStorage.removeItem('token');
            await context.commit('setToken', null)
            await context.commit('setUserInfo', null);
            return
        }
        await context.commit('setUserInfo', response.data.user);
    },
}