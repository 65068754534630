<template>
  <v-row justify="center">
    <v-dialog
        persistent
        v-model="dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            v-if="bottomStyle"
            color="primary"
            v-bind="attrs"
            v-on="on"
        >
          Crear Asignatura
        </v-btn>
        <v-btn
            v-else
            text
            block
            v-bind="attrs"
            v-on="on"
        >
          Crear Asignatura
        </v-btn>

      </template>

      <v-container fluid>
        <v-row justify="center">
          <v-col justify="center">
            <v-card>
              <v-form
                  @submit.prevent="submit"
                  ref="form"
                  lazy-validation
              >
                <v-card-text>
                  <v-text-field
                      v-model="form.name"
                      prepend-icon="mdi-format-title"
                      label="Nombre Asignatura"
                  ></v-text-field>
                  <v-text-field
                      v-model="form.year"
                      type="number"
                      prepend-icon="mdi-calendar"
                      label="Año"
                  ></v-text-field>
                  <v-select
                      v-model="form.period"
                      :items="period"
                      prepend-icon="mdi-calendar-check-outline"
                      label="Periodo"
                      required
                  ></v-select>
                  <v-textarea
                      prepend-icon="mdi-format-text-variant-outline"
                      v-model="form.description"
                      auto-grow
                      filled
                      color="deep-purple"
                      label="Breve Descripción"
                      rows="3"
                  ></v-textarea>

                  <v-file-input
                      accept="image/jpg"
                      placeholder="Seleccione una Imágen"
                      prepend-icon="mdi-camera"
                      label="Imágen(Opcional)"
                  ></v-file-input>
                </v-card-text>

                <v-card-actions>
                  <v-btn
                      color="red"
                      text
                      @click="cancel"
                  >
                    Salir
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="primary"
                      text
                      type="submit"
                  >
                    Crear Asignatura
                  </v-btn>
                </v-card-actions>
              </v-form>

            </v-card>
          </v-col>

        </v-row>
      </v-container>
      <div class="text-center ma-2">
        <v-snackbar
            :color="color"
            v-model="snackbar"
        >
          {{ text }}.

          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                @click="snackbar = false"
            >
              OK
            </v-btn>
          </template>
        </v-snackbar>
      </div>
    </v-dialog>

  </v-row>
</template>

<script>

export default {
  props: {
    bottomStyle: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      snackbar: false,
      text: '',
      color: '',
      dialog: false,
      period: ["Anual", "Semestral", "Trimestral"],
      form: {
        name: '',
        year: null,
        period: '',
        description: '',
      },
    }
  },
  methods: {

    resetForm() {
      this.form = {
        name: '',
        year: null,
        period: '',
        description: '',
      }
    },

    async submit() {
      if (this.form.name === '' || this.form.period === '' || this.form.description === '' || this.form.year === null) {
        this.setSnackBar('red', 'Debe rellenar todos los campos', true);
        return;
      }
      await this.$store.dispatch('createLesson', this.form)
          .then(() => {
            this.resetForm();
            this.setSnackBar('green', 'Asignatura creada con éxito', true);
          })
          .catch(() => this.setSnackBar('red', `Ya existe la asignatura con nombre : ${ this.form.name }`, true));




    },

    setSnackBar(color, text, snack) {
      this.color = color;
      this.text = text;
      this.snackbar = snack
    },

    cancel() {
      this.dialog = false;
    }

  }
}
</script>