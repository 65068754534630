<template>
  <div>
    <v-tabs  v-model="tab" class="mt-7">
      <v-tab v-for="(_, i) in questions" :href="`#${i}`" :key="i">Pregunta {{ i + 1 }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(question, index) in questions" :key="index" :value="`${index}`">
        <v-card
            :key="index"
            class="mt-4"
            width="100vw"
            height="100vh"
            v-if="!loading"
        >
          <v-card-title>
            <p v-if="finish">Puntaje Obtenido: {{ points }}/{{ totalPoints }} </p>
          </v-card-title>
          <v-form @submit.prevent="submit">
            <v-card-text>
              <div class="text--primary">
                <p class="text-justify" v-if="question.question.description !== ''">
                  {{ question.question.description }}
                </p>
                <p class="text-justify">{{ question.question.title }} </p>
              </div>

              <v-container fluid v-if="question.question.type === 0">
                <v-textarea
                    :disabled="finish"
                    v-model="answers[index].answer"
                    background-color="light-blue lighten-4"
                    color="teal"
                >
                  <template v-slot:label>
                    <div>
                      Respuesta:
                    </div>
                  </template>
                </v-textarea>
              </v-container>

              <v-container fluid v-if="question.question.type === 1">
                <v-radio-group v-model="answers[index].answer">
                  <v-radio
                      :disabled="finish"
                      v-for="(alternative, i) in question.answer"
                      :key="i"
                      :label="alternative.description"
                      :value="alternative"
                  >
                    <template v-slot:label>
                      <h4 v-if="!finish" class="text-left">
                        {{ alternative.description }}
                      </h4>
                      <h4 v-if="finish && alternative.isCorrect" class="text-left" style="color: green">
                        {{ alternative.description }} (Respuesta Correcta )
                      </h4>
                      <h4 v-else-if="finish && !alternative.isCorrect" class="text-left" :style="{color: alternative.id === answers[index].answer.id ? 'red' : '' }">
                        {{ alternative.description }}
                      </h4>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-container>


              <v-container fluid v-if="question.question.type === 2">
                <v-checkbox
                    :disabled="finish"
                    v-for="(alternative, i) in question.answer"
                    :key="i"
                    v-model="answers[index].answer"
                    :label="alternative.description"
                    :value="alternative"
                >
                  <template v-slot:label>
                    <h4 v-if="!finish" class="text-left">
                      {{ alternative.description }}
                    </h4>
                    <h4 v-if="finish && alternative.isCorrect" class="text-left" style="color: green">
                      {{ alternative.description }} (Respuesta Correcta )
                    </h4>
                    <h4 v-else-if="finish && !alternative.isCorrect" class="text-left" :style="{color: answers[index].answer.some(val => val.id === alternative.id ) ? 'red' : '' }">
                      {{ alternative.description }}
                    </h4>
                  </template>
                </v-checkbox>
              </v-container>
              <p class="text-justify">Puntaje Pregunta: {{ question.question.points }} puntos. </p>

            </v-card-text>



            <v-card-actions>
              <v-btn
                  v-if="tab!=='0'"
                  @click="tab=`${parseInt(tab) - 1}`"
                  text
                  color="deep-purple accent-4"
              >
                Anterior
              </v-btn>
              <v-btn
                  v-if="tab!==`${questions.length - 1}`"
                  @click="tab=`${parseInt(tab) + 1}`"
                  text
                  color="deep-purple accent-4"
              >
                Siguiente
              </v-btn>
              <p v-if="!canSendForm && tab === `${questions.length - 1}`" class="text-justify mt-4" style="color: red">
                Debe responder todas las preguntas para enviar formulario.
              </p>
              <v-btn
                  v-if="tab===`${questions.length - 1}` && !finish && canSendForm"
                  @click="submit"
                  text
                  color="deep-purple accent-4"
              >
                Terminar
              </v-btn>
            </v-card-actions>
          </v-form>

        </v-card>

      </v-tab-item>


    </v-tabs-items>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  props: {
    id: {
      type: Number
    },
    time: {
      type: Number
    },
    paragraphQuestions: {
      type: Array
    },
    highLights: {
      type: Array
    },
    words: {
      type: Array
    }
  },
  data() {
    return {
      tab: null,
      answers: [],
      questions: [],
      finish: false,
      loading: false,
      points: 0,
      totalPoints: 0
    }
  },
  computed: {
    canSendForm() {
      if(this.answers.some(val => {
        if(val.type === 0 && val.answer === '' || val.type === 1 && val.answer === 1 || val.type === 2 && val.answer.length === 0 ) {
          return true
        }
      })) return false;
      return true;
    }
  },
  methods: {
    getIcon(alternative, answer) {
      if(alternative.id === answer.id && alternative.isCorrect) {
        return 'mdi-check-circle'
      }
      if(alternative.id === answer.id && !alternative.isCorrect) {
        return 'mdi-close-circle'
      }
      if(alternative.id !== answer.id) {
        return 'mdi-checkbox-blank-circle-outline'
      }
    },
    checkPoints(answers) {
      // let sumMultiple = 0;
      answers.forEach( answer => {
        if (answer.type === 1 && answer.answer.isCorrect) this.points += answer.points;
        if (answer.type === 2) {
          if(answer.answer.some(element => !element.isCorrect)) return;
          
        }
      });
    },
    async submit() {
      let id = this.$route.params.id;

      await Swal.fire({
        title: '¿Desea terminar la evaluación?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí!',
        cancelButtonText: 'Volver'
      }).then( async (result) => {
        if (result.isConfirmed) {
          await Swal.fire(
              'Completada!',
              'Las respuestas han sido contestadas',
              'success'
          )
          this.checkPoints(this.answers)
          this.tab = 0;
          this.finish = true;
          await axios.post('reply', {
            time: this.time,
            answer: this.answers,
            question: this.paragraphQuestions,
            activityId: id,
            points: this.points,
            highLight: this.highLights,
            saveWord: this.words
          })
        }
      })


    }
  },
  async created() {
    this.loading = true;
    let id = this.$route.params.id;
    await axios.get('activity/' + id)
        .then( async response => {
          const { questions } = await response.data;
          this.questions = questions;
        })
    this.questions.map( async questions => {
      this.totalPoints += questions.question.points;
      if (questions.question.type === 0) {
        this.answers.push({ idQuestion: questions.question.id, type: 0, answer: '', points: questions.question.points })
      }
      if (questions.question.type === 1) {
        this.answers.push({ idQuestion: questions.question.id, type: 1, answer: 1, points: questions.question.points })
      }
      if (questions.question.type === 2) {
        this.answers.push({ idQuestion: questions.question.id, type: 2, answer: [], points: questions.question.points })
      }
    })
    this.loading = false;
  }
}
</script>