<template>
  <div>

    <v-tabs  v-model="tab">
      <v-tab href="#tab-1">Texto</v-tab>
      <v-tab href="#tab-2">Palabras Guardadas</v-tab>
      <v-tab href="#tab-3" v-if="finish">Actividades</v-tab>
      <v-tab v-if="!intervalTime" disabled>Tiempo {{ time }}</v-tab>
      <v-tab v-else disabled>Tiempo {{ intervalTime }}</v-tab>
    </v-tabs>



    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <v-card
            class="mt-4"
            width="100vw"
        >
          <v-card-title v-if="!hideTitle">
            <v-row>
              <v-col cols="4">
                <v-text-field
                    class="text--accent-4 mx-0 py-0"
                    readonly
                    label="Autor"
                    :value="textData.author"
                />
              </v-col>
              <v-col cols="8">
                <v-text-field
                    class="text--accent-4 mx-0 py-0"
                    readonly
                    label="Título"
                    :value="textData.title"
                />
              </v-col>
              <v-col cols="8">
                <v-text-field
                    v-if="textData.font && textData.font.length > 0"
                    class="text--accent-4 mx-0 py-0"
                    readonly
                    label="Fuente"
                    :value="textData.font"
                />
              </v-col>
              <v-col cols="2">
                <v-text-field
                    v-if="textData.publicationYear && textData.publicationYear.length > 0"
                    class="text--accent-4 mx-0 py-0"
                    readonly
                    label="Año de publicación"
                    :value="textData.publicationYear"
                />
              </v-col>
              <v-col cols="2">
                <v-text-field
                    v-if="textData.type && textData.type.length > 0"
                    class="text--accent-4 mx-0 py-0"
                    readonly
                    label="Tipo de texto"
                    :value="textData.type"
                />
              </v-col>
              <v-col cols="10">
                <v-text-field
                    v-if="textData.description && textData.description.length > 0"
                    class="text--accent-4 mx-0 py-0"
                    readonly
                    label="Descripción"
                    :value="textData.description"
                />
              </v-col>
              <v-col cols="2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        @click="hideTitle = true"
                        v-bind="attrs"
                        v-on="on"
                    >
                      mdi-eye-off
                    </v-icon>
                  </template>
                  <span>Esconder Info</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-title>

          <v-tooltip bottom v-if="hideTitle">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  @click="hideTitle = false"
                  v-bind="attrs"
                  v-on="on"
              >
                mdi-eye
              </v-icon>
            </template>
            <span>Mostrar Info</span>
          </v-tooltip>



          <v-card-title v-if="!finish" style="background-color: aliceblue">
            <v-row class="mt-3" align="center" justify="space-around">

              <v-col xl="1" lg="2" sm="6" md="3">
                <v-btn @click="changeTextSize('minus')" block>
                  Aa-
                </v-btn>
              </v-col>
              <v-col xl="1" lg="2" sm="6" md="3">
                <v-btn @click="changeTextSize('initial')" block>
                  Aa
                </v-btn>
              </v-col>
              <v-col xl="1" lg="2" sm="6" md="3">
                <v-btn @click="changeTextSize('plus')" block>
                  Aa+
                </v-btn>
              </v-col>

              <v-col xl="1" lg="2" sm="6" md="3">
                <v-btn @click="raeSearch" block>
                  Buscar
                </v-btn>
              </v-col>

              <v-col xl="1" lg="2" sm="6" md="3">
                <v-btn @click="setHighLight" block>
                  Guardar
                </v-btn>
              </v-col>

              <v-col xl="1" lg="2" sm="6" md="3">
                <v-btn @click="highLightWord" block>
                  Resaltar
                </v-btn>
              </v-col>
              <v-col xl="1" lg="2" sm="6" md="3">
                <v-btn @click="noHighLightWord" block>
                  Quitar
                </v-btn>
              </v-col>
              <v-col xl="1" lg="2" sm="6" md="3">
                <v-btn block color="success" @click="finishRead">
                  Terminar
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-container  fluid ref="target">
              <v-row class="text--primary" v-for="(data,i) in textParagraphs" :key="i" cols="11">
                <v-col>
                  <p class="text-justify" :style="{fontSize: textSize }" v-html="data.description">
                  </p>
                  <v-text-field
                      v-if="paragraphQuestion[i] && paragraphQuestion[i].visible"
                      :disabled="finish"
                      @click:prepend="paragraphQuestion[i].visible = false"
                      v-model="paragraphQuestion[i].answer"
                      :prepend-icon="paragraphQuestion[i].answer === '' ? 'mdi-close' : 'mdi-check-all'"
                      :label="`Pregunta Párrafo ${i+1}`"
                  />
                </v-col>
                <v-col cols="1">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                          @click="paragraphQuestion[i].visible = !paragraphQuestion[i].visible"
                          :color="paragraphQuestion[i] && paragraphQuestion[i].answer.length > 0 ? 'success' : 'orange'"
                          v-bind="attrs"
                          v-on="on"
                      >
                        mdi-comment-question
                      </v-icon>
                    </template>
                    <span>{{ paragraphQuestion[i] && paragraphQuestion[i].answer.length > 0 ? 'Pregunta Realizada.' : 'Realizar Pregunta.' }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

        </v-card>
      </v-tab-item>

      <v-tab-item value="tab-2">
        <words-table :words="savedWords"/>
      </v-tab-item>

      <v-tab-item value="tab-3">
        <student-activity :time="time" :words="savedWords" :paragraph-questions="paragraphQuestion" :highLights="highLights"/>
      </v-tab-item>


    </v-tabs-items>


  </div>
</template>

<script>
import axios from "axios";
import WordsTable from "../../../../components/students/WordsTable";
import StudentActivity from "../../../../components/students/StudentActivity";
import Swal from "sweetalert2";

export default {
  components: {StudentActivity, WordsTable },
  data () {
    return {
      hideTitle: false,
      savedWords: [],
      finish: false,
      intervalTime: null,
      tab: null,
      textData: [],
      textParagraphs: [],
      initial: 17,
      auxInitial: 17,
      textSize: '17px',
      highLights: [],
      canHighLight: false,
      time: 0,
      activityId: null,
      paragraphQuestion: [],
      loading: null,
      questionsFromText: []
    }
  },
  methods: {
    async finishRead() {
      await Swal.fire({
        title: '¿Desea finalizar la lectura?',
        text: "Pasará a responder preguntas",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí!',
        cancelButtonText: 'Volver'
      }).then( async (result) => {
        if (result.isConfirmed) {
          await Swal.fire(
              'Completada!',
              'La lectura ha sido completada..',
              'success'
          )
          this.finish = true;
          this.tab = 'tab-3';
          clearInterval(this.time);
        }
      })

    },
    setHighLight() {
      this.canHighLight = true;
      this.saveWord();
    },
    changeTextSize(statement) {

      if ( statement === 'plus' ) {
        this.initial++;
        this.textSize = `${ this.initial }px`;
      }

      if ( statement === 'minus' ) {
        if (this.initial > 1) {
          this.initial--;
          this.textSize = `${ this.initial }px`;
        }
      }

      if ( statement === 'initial' ) {
        this.initial = this.auxInitial;
        this.textSize = `${ this.initial }px`;
      }

    },

    async getText() {
      await axios.get('textActivity/' + this.activityId)
          .then( (response) => {
            const { text, paragraph } = response.data;
            this.textData = text;
            this.textParagraphs = paragraph;
          })
          .catch( error => console.log('aca', error));
    },

    searchWord(word) {
      return this.savedWords.find( data => data === word)
    },

    noHighLightWord() {
      let word = window.getSelection().toString().trim();
      let i;
      if(word.length === 0) return;

      for( let i = 0; i < this.textParagraphs.length; i++) {
        this.textParagraphs[i].description = this.textParagraphs[i].description.replaceAll(word, '<strong style="background-color: white; font-weight: normal">' + word + '</strong>');
      }
      this.highLights.find((item, index) => {
        if(item === word) i = index;
      })

      this.highLights.splice(i, 1);
      
      
    },
    highLightWord() {
      let word = window.getSelection().toString().trim();
      if(word.length === 0) return;

      for( let i = 0; i < this.textParagraphs.length; i++) {
        this.textParagraphs[i].description = this.textParagraphs[i].description.replaceAll(word, '<strong style="background-color: yellow">' + word + '</strong>');
      }
      
      if(!this.highLights.find( val => val === word)) this.highLights.push(word)

    },

    raeSearch() {
      let word = window.getSelection().toString().trim();
      window.open(`https://dle.rae.es/${ word }?m=form`, '_blank', "width=600,height=600");
    },
    saveWord() {

      let highlight = window.getSelection().toString();
      if ( highlight.trim() !== '' ) {


        let count = 0;

        const words = highlight.split(' ');

        words.find( (data) => {
          if(data === '') count ++;
        } )

        if ( this.canHighLight && (words.length - count) === 1) {

          if ( this.searchWord(highlight) ) {
            this.canHighLight = false;
            return;
          }

          this.savedWords.push(highlight);
          this.canHighLight = false;

        }

      }
      this.canHighLight = false;
    }
  },

  async created() {


    this.activityId = this.$route.params.id;


    await this.getText();

    this.loading = true;
    await this.textParagraphs.forEach( (paragraph) => {
      this.paragraphQuestion.push({ id: paragraph.id, answer: '', textId: this.textData.id, visible: false })
    })

    this.loading = false;



    document.addEventListener('mouseup', event => {
      if (event.target === this.$refs.target || event.target.contains(this.$refs.target))
        this.saveWord();
    });

    setInterval(() => this.time++, 1000);

  }
}
</script>
