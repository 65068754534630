<template>
  <loading-component v-if="loading"/>
  <div v-else>
    <v-tabs  v-model="tab">
      <v-tab href="#tab-1">Texto</v-tab>
      <v-tab href="#tab-2">Palabras Guardadas</v-tab>
      <v-tab href="#tab-3">Actividades</v-tab>
      <v-tab disabled>Tiempo {{ intervalTime }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <v-card
            class="mt-4"
            width="100vw"
        >

          <v-card-title v-if="!hideTitle" style="border-width: medium">
            <v-row>
              <v-col cols="4">
                <v-text-field
                    class="text--accent-4 mx-0 py-0"
                    readonly
                    label="Autor"
                    :value="textData.author"
                />
              </v-col>
              <v-col cols="8">
                <v-text-field
                    class="text--accent-4 mx-0 py-0"
                    readonly
                    label="Título"
                    :value="textData.title"
                />
              </v-col>
              <v-col cols="8">
                <v-text-field
                    v-if="textData.font && textData.font.length > 0"
                    class="text--accent-4 mx-0 py-0"
                    readonly
                    label="Fuente"
                    :value="textData.font"
                />
              </v-col>
              <v-col cols="2">
                <v-text-field
                    v-if="textData.publicationYear && textData.publicationYear.length > 0"
                    class="text--accent-4 mx-0 py-0"
                    readonly
                    label="Año de publicación"
                    :value="textData.publicationYear"
                />
              </v-col>
              <v-col cols="2">
                <v-text-field
                    v-if="textData.type && textData.type.length > 0"
                    class="text--accent-4 mx-0 py-0"
                    readonly
                    label="Tipo de texto"
                    :value="textData.type"
                />
              </v-col>
              <v-col cols="10">
                <v-text-field
                    v-if="textData.description && textData.description.length > 0"
                    class="text--accent-4 mx-0 py-0"
                    readonly
                    label="Descripción"
                    :value="textData.description"
                />
              </v-col>
              <v-col cols="2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        @click="hideTitle = true"
                        v-bind="attrs"
                        v-on="on"
                    >
                      mdi-eye-off
                    </v-icon>
                  </template>
                  <span>Esconder Info</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-title>

          <v-tooltip bottom v-if="hideTitle">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                  @click="hideTitle = false"
                  v-bind="attrs"
                  v-on="on"
              >
                mdi-eye
              </v-icon>
            </template>
            <span>Mostrar Info</span>
          </v-tooltip>

          <v-divider
              class="mx-4"
              vertical
          ></v-divider>

          <v-card-text>
            <v-container fluid ref="target">
              <v-row>
                <v-col class="text--primary" v-for="(data,i) in textParagraphs" :key="i" cols="12">
                  <p class="text-justify" :style="{fontSize: textSize }" v-html="data.description">
                  </p>
                  <v-text-field
                      disabled
                      :label="questionsFromText[i] && questionsFromText[i].answer !== '' ? questionsFromText[i].answer : 'No se realizó pregunta'"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

        </v-card>
      </v-tab-item>

      <v-tab-item value="tab-2">
        <words-table :words="savedWords"/>
      </v-tab-item>

      <v-tab-item value="tab-3">
        <student-reply/>
      </v-tab-item>


    </v-tabs-items>


  </div>
</template>

<script>
import axios from "axios";
import WordsTable from "../../../../components/students/WordsTable";
import StudentReply from '../../../../components/students/StudentReply.vue';
import LoadingComponent from "../../../../components/UI/LoadingComponent";

export default {
  components: {LoadingComponent, WordsTable, StudentReply},
  data () {
    return {
      hideTitle: false,
      savedWords: [],
      intervalTime: null,
      tab: null,
      textData: [],
      textParagraphs: [],
      textSize: '17px',
      highLights: [],
      activityId: null,
      paragraphQuestion: [],
      reply: null,
      loading: null,
      questionsFromText: []
    }
  },
  methods: {

    async getText() {
      await axios.get('textActivity/' + this.activityId)
          .then( (response) => {
            const { text, paragraph } = response.data;
            this.textData = text;
            this.textParagraphs = paragraph;
          })
          .catch( error => console.log(error));
    },

  },

  async created() {
    this.loading = true;

    this.activityId = this.$route.params.id;

    await axios.get('studentReplies_activity/' + this.activityId)
        .then(response => {
          const { reply } = response.data;
          this.reply = reply;
          this.savedWords = JSON.parse(reply.saveWord);
          this.questionsFromText = JSON.parse(reply.question)

        })


    await this.getText();
    this.intervalTime = this.reply.time;

    this.loading = false;


  }
}
</script>
