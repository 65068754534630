<template>
  <v-container fluid>
    <v-row class="mt-5" v-if="!loading">
      <v-col v-for="(data, i) in questions" :key="i">
        <card-component image="question" :description="data.title">
          <v-btn @click="questionDetails(data.id)">
            Ver Detalles
          </v-btn>
          <v-chip
              :color="chipColor(data.difficulty).color"
              class="ma-2"
          >
            {{ chipColor(data.difficulty).difficulty }}
          </v-chip>
        </card-component>
      </v-col>
      <v-col v-if="questions.length === 0">
        <v-img
            width="60%"
            :src="require('@/assets/images/nodata.jpg')"
        ></v-img>
        <p>Actualmente no posee preguntas creadas</p>
        <question-form :text-id="parseInt(textId)"/>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col>
        <v-progress-circular
            v-if="loading"
            size="200"
            :width="3"
            color="primary"
            indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CardComponent from "../../../../components/UI/CardComponent";
import QuestionForm from "../../../../components/teacher/forms/QuestionForm";
export default {
  components: {QuestionForm, CardComponent },
  computed: {
    questions() {
      return this.$store.getters.getQuestions;
    }
  },
  data() {
    return {
      textId: null,
      loading: null
    }
  },
  methods: {
    questionDetails(id) {
      this.$router.push('/profe/pregunta/' + id);
    },
    chipColor(difficulty) {
      if (difficulty === 0) {
        return {color: 'green', difficulty: 'Fácil'}
      }
      if (difficulty === 1) {
        return {color: 'orange', difficulty: 'Intermedio'}
      }
      if (difficulty === 2) {
        return {color: 'red', difficulty: 'Difícil'}
      }
    },
  },
  async created() {
    this.loading = true;
    this.textId = this.$route.params.id;
    await this.$store.dispatch('questions', this.textId);
    this.loading = false;

  }
}
</script>