import axios from "axios";

export default {
    async lessonsStudent(context) {
        let response = await axios.get('student_lesson');

        if ( !response.data.ok ) {
            const error = new Error( response.message || 'Failed auth');
            throw error;
        }

        await context.commit('setStudentLessons', response.data.lessons);

    },
    async textsStudent(context) {

        let response = await axios.get('text');

        if ( !response.data.ok ) {
            const error = new Error( response.message || 'Failed auth');
            throw error;
        }

        await context.commit('setStudentTexts', response.data.texts);

    },
    async activitiesStudent(context, data) {
        let response = await axios.get('activityLesson/' + data);

        if ( !response.data.ok ) {
            const error = new Error( response.message || 'Failed auth');
            throw error;
        }

        await context.commit('setStudentActivitiesReply', response.data.reply);
        await context.commit('setStudentActivitiesNoReply', response.data.noReply);


    },
}