import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeScreen from '../pages/shared/HomeScreen';
import LoginScreen from '../pages/shared/LoginScreen';
import { teacherRoute } from "./teacherRoute";
import { studentRoute } from "./studentRoute";
import store from "../store";
import ResetPassword from "../pages/shared/ResetPassword";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        { path: '/', redirect: '/home' },
        { path: '/reset/:token', component: ResetPassword },
        { path: '/home', component: HomeScreen, name: 'Home',
            async beforeEnter(to, from, next) {
                if(!store.getters.getToken) next();
                else {
                    await store.dispatch('userInfo')
                        .then(() => {
                            if (store.getters.getUserInfo.rol === 0) next('estudiante')
                            else if (store.getters.getUserInfo.rol === 1) next('profe');
                            else next('login');
                        })
                        .catch(() => next())
                }
            },
        },
        { path: '/login', component: LoginScreen, name: 'Login',
            async beforeEnter(to, from, next) {
                if(!store.getters.getToken) next();
                else {
                    await store.dispatch('userInfo')
                        .then(() => {
                            if (store.getters.getUserInfo.rol === 0) next('estudiante')
                            else if (store.getters.getUserInfo.rol === 1) next('profe');
                            else next();
                        })
                        .catch(() => next())
                }
            },
        },
        teacherRoute,
        studentRoute,
        {
            path: '/:catchAll(.*)',
            name: 'NotFound',
            redirect: '/home'
        },
    ]
})


export default router;