<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            v-bind="attrs"
            v-on="on"
        >
          Ver Estudiantes
        </v-btn>

      </template>

      <v-container fluid>
        <v-row justify="center">
          <v-col justify="center">
            <v-card v-if="studentsNames.length === 0">
              <v-card-text>
                <p >Ningún Estudiante ha completado la actividad</p>
              </v-card-text>
            </v-card>
            <v-card v-else>
              <v-card-text>
                <v-autocomplete
                    @change="studentDetails"
                    label="Estudiantes"
                    :items="studentsNames"
                    item-text="name"
                    return-object
                />
              </v-card-text>

              <v-card-actions>
                <v-btn
                    text
                >
                  Cancelar
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                >
                  Ver Respuestas
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
  </v-row>
</template>

<script>

import axios from "axios";

export default {
  props: {
    activityId: {
      type: Number,
    }
  },

  data () {
    return {
      dialog: false,
      studentsNames: [],
    }
  },
  methods: {
    studentDetails(student) {
      this.$router.push('/profe/respuestas/' + student.id + '/' + this.activityId)
    }
  },
  created() {
    axios.get('studentReplies/' + this.activityId).then(response => {
      const { students } = response.data;
      this.studentsNames = students;
      this.studentsNames.map( data => data.name = data.name + ' ' + data.name + ' ' + data.secondLastName)
    })
  }

}
</script>