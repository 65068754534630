export default {
    async setToken(state, payload) {
        state.token = payload;
    },
    async setLessons(state, payload) {
        state.lessons = payload;
    },
    async setTexts(state, payload) {
        state.texts = payload;
    },
    async setUserInfo(state, payload) {
        state.userInfo = payload;
    },

}