<template>
  <v-card
      fluid
      class="container--fluid"
      height="100%"
      width="100%"
  >
    <v-navigation-drawer
        permanent
        absolute
        dark
        src="https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg"
        width="100%"
        height="100%"
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              Rol
            </v-list-item-title>
            <v-list-item-subtitle>Estudiante</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              {{ userInfo ? `${userInfo.name} ${userInfo.lastname} ${userInfo.secondLastName}` : '' }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ userInfo ? userInfo.email : '' }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list>
        <v-list-item link @click="changePage('asignaturas')">
          <v-list-item-icon>
            <v-icon>mdi-school</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Asignaturas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-row>
        <v-col class="mt-10" cols="12">
          <v-btn @click="logOut" block color="error">Cerrar Sesión</v-btn>
        </v-col>
      </v-row>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
export default {
  computed: {
    userInfo() {
      return this.$store.getters.getUserInfo;
    }
  },
  methods: {
    async logOut() {
      await this.$store.dispatch('logOut');
      await this.$router.push('/login');
    },
    changePage(where) {
      if (where === 'mensajes' && this.$route.path !== '/estudiante/'+ where) {
        this.$router.push('/estudiante/' + where)
      }
      if (where === 'textos' && this.$route.path !== '/estudiante/' + where) {
        this.$router.push('/estudiante/' + where)
      }
      if (where === 'asignaturas' && this.$route.path !== '/estudiante/' + where) {
        this.$router.push('/estudiante/' + where)
      }
    }
  }
}
</script>