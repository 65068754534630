export default {
    setStudentInLessons(state, payload) {
        state.studentsInLesson = payload;
    },
    setQuestions(state, payload) {
        state.questions = payload;
    },
    setQuestion(state, payload) {
        state.question = payload;
    },
    setAnswers(state, payload) {
        state.answers = payload;
    }
}