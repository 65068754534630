<template>
  <v-row justify="center">
    <v-dialog
        persistent
        v-model="dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            v-if="bottomStyle"
            color="primary"
            v-bind="attrs"
            v-on="on"
        >
          Añadir Profesor
        </v-btn>
        <v-btn
            v-else
            text
            block
            v-bind="attrs"
            v-on="on"
        >
          Añadir Profesor
        </v-btn>

      </template>

      <v-container fluid>
        <v-row justify="center">
          <v-col justify="center">
            <v-card>
              <v-form
                  @submit.prevent="submit"
                  ref="form"
                  lazy-validation
              >
                <v-card-text>
                  <v-text-field
                      :rules="[rules.required]"
                      v-model="form.name"
                      prepend-icon="mdi-format-title"
                      label="Nombre"
                  ></v-text-field>
                  <v-text-field
                      :rules="[rules.required]"
                      v-model="form.lastName"
                      prepend-icon="mdi-format-title"
                      label="Apellido Paterno"
                  ></v-text-field>
                  <v-text-field
                      :rules="[rules.required]"
                      v-model="form.secondLastName"
                      prepend-icon="mdi-format-title"
                      label="Apellido Materno"
                  ></v-text-field>
                  <v-text-field
                      :rules="emailRules"
                      v-model="form.email"
                      prepend-icon="mdi-format-title"
                      label="Email"
                  ></v-text-field>
                  <v-text-field
                      type="password"
                      :rules="[rules.required]"
                      v-model="form.password"
                      prepend-icon="mdi-format-title"
                      label="Contraseña(Mínimo 8 caracteres)"
                  ></v-text-field>
                  <v-text-field
                      type="password"
                      :rules="[rulesPassword.min]"
                      hint="Mínimo 8 caracteres"
                      v-model="password"
                      prepend-icon="mdi-format-title"
                      label="Repetir Contraseña"
                  ></v-text-field>
                </v-card-text>

                <v-card-actions>
                  <v-btn
                      text
                      @click="cancel"
                  >
                    Cancelar
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="primary"
                      text
                      type="submit"
                  >
                    Añadir Profesor
                  </v-btn>
                </v-card-actions>
              </v-form>

            </v-card>
          </v-col>

        </v-row>
      </v-container>
    </v-dialog>
  </v-row>
</template>

<script>

import axios from "axios";
import Swal from "sweetalert2";

export default {
  props: {
    bottomStyle: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      password: '',
      dialog: false,
      period: ["Anual", "Semestral", "Trimestral"],
      form: {
        name: '',
        lastName: '',
        secondLastName: '',
        email: '',
        password: '',
        rol: 1
      },
      emailRules: [
        v => !!v || "Campo Requerido",
        v => /.+@.+\..+/.test(v) || "E-mail debe ser válido"
      ],
      rules: {
        required: value => !!value || 'Campo Requerido',
      },
      rulesPassword: {
        required: value => !!value || "Campo Requerido",
        min: v => (v && v.length >= 8) || "Min 8 caracteres"
      }
    }
  },
  methods: {

    resetForm() {
      this.form = {
        name: '',
        lastName: '',
        secondLastName: '',
        email: '',
        password: '',
        rol: 1
      }
    },

    async submit() {

      if (this.form.name === '' || this.form.lastName === '' || this.form.secondLastName === '' || this.form.email === null || this.form.password.length < 8) {
        return;
      }

      await axios.post('createTeacher', this.form)
          .then(() => {
            this.resetForm();
            this.dialog = false;
            return Swal.fire(
                '',
                'Profesor creado con éxito!',
                'success'
            )
          })
          .catch(() => {
            return Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Mail debe ser único.'
            })
          })



    },

    cancel() {
      this.dialog = false;
    }

  }
}
</script>