<template>
  <v-card>
    <v-card-title>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar Estudiantes"
          single-line
          hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
        :search="search"
        v-model="selected"
        :headers="headers"
        :items="students"
        item-key="id"
        class="elevation-1"
    >
      <template v-slot:no-data>
        <p>
          No tiene estudiantes en la asignatura.
        </p>
      </template>
    </v-data-table>

  </v-card>
</template>

<script>

export default {
  data: () => ({
    answersStudent: {
      paragraphs: '',
      answers: ''
    },
    dialog: false,
    dialogDelete: false,
    search: '',
    selected: [],
    headers: [
      {
        text: 'Nombre',
        align: 'start',
        value: 'name',
      },
      { text: 'Apellido Paterno', value: 'lastName' },
      { text: 'Apellido Materno', value: 'secondLastName' },
      { text: 'E-mail', value: 'email' },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      name: '',
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: '',
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
  }),

  computed: {
    students() {
      return this.$store.getters.getStudentsInLesson;
    },
  },

  created () {
    this.getStudents();
  },

  methods: {
    async getStudents() {
      let id = this.$route.params.id;
      await this.$store.dispatch('studentsInLesson', id);
    },

  },
}
</script>