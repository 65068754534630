<template>
  <v-card
      class="mt-4"
      width="100vw"
  >
    <v-card-text>

      <v-card-text>
        <p class="text-justify">
          <strong>Dificultad: </strong>{{ questionDifficulty }}
        </p>
        <p class="text-justify">
          <strong>Tipo: </strong>{{ questionType }}
        </p>
        <p class="text-justify">
          <strong>Dificultad: </strong>: {{ questionDifficulty }}
        </p>
        <p v-if="question.description !== ''" class="text-justify">
          <strong>Descripción: </strong>{{ question.description }}
        </p>
        <p class="text-justify">
          <strong>Título: </strong>{{ question.title }}
        </p>
        <div v-for="(alternative, i) in answers" :key="i">
          <p class="text-justify" :style="{ color: alternative.isCorrect ? 'green' : 'red' }">{{ i + 1 }}: {{ alternative.description }} <strong v-if="alternative.isCorrect">(Respuesta Correcta)</strong></p>
        </div>

      </v-card-text>


      <v-card-subtitle><p class="text-justify font-weight-black">Puntos: {{ question.points }}</p></v-card-subtitle>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      id: null
    }
  },
  computed: {
    question() {
      return this.$store.getters.getQuestion;
    },
    answers() {
      return this.$store.getters.getAnswers;
    },
    questionType() {
      if (this.question?.type === 0 ) {
        return 'Desarrollo';
      }
      if (this.question?.type === 1 ) {
        return 'Selección Múltiple (una respuesta correcta)';
      }
      if (this.question?.type === 2 ) {
        return 'Selección Múltiple (varias respuestas correctas)';
      }
      return '';
    },
    questionDifficulty() {
      if (this.question?.difficulty === 0 ) {
        return 'Fácil';
      }
      if (this.question?.difficulty === 1 ) {
        return 'Intermedio';
      }
      if (this.question?.difficulty === 2 ) {
        return 'Difícil';
      }
      return '';
    }
  },
  async created() {
    this.id = this.$route.params.id;
    await this.$store.dispatch('question', this.id);
  }
}
</script>