<template>
  <div>
    <v-tabs  v-model="tab">
      <v-tab href="#tab-1">Párrafos</v-tab>
      <v-tab href="#tab-2">Preguntas</v-tab>
      <v-tab href="#tab-3">Palabras Guardadas</v-tab>
<!--      <v-btn class="mt-2" color="success" @click="downloadReport">Descargar Reporte</v-btn>-->
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <v-card
            class="mt-4"
            width="100vw"
        >
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col class="text--primary" v-for="(paragraph,i) in paragraphs" :key="i" cols="12">
                  <p class="text-justify">
                    {{ paragraph.description.replace(highLight[0], 'acaca') }}
                  </p>
                  <v-text-field
                      disabled
                      prepend-icon="mdi-check-all"
                      :value="paragraphAnswer[i].answer !== '' ? paragraphAnswer[i].answer : 'Estudiante no realizó pregunta.'"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item value="tab-2">

        <v-card-title>
          <p class="text-justify">Tiempo de lectura: <strong>{{ time }} segundos</strong></p>
        </v-card-title>

        <v-card
            v-for="(question, index) in questions"
            :key="index"
            class="mt-4"
            width="100vw"
        >


          <v-card-title>
            Pregunta: {{index + 1}}
          </v-card-title>
          <v-card-text>
            <div class="text--primary">
              <p class="text-justify">
                {{ question.question.description }}
              </p>
              <p class="text-justify">{{ question.question.title }} </p>
            </div>
          </v-card-text>
          <v-container fluid v-if="question.question.type === 0">
            <v-textarea
                disabled
                background-color="light-blue lighten-4"
                color="teal"
            >
              <template v-slot:label>
                <div>
                  Respuesta: {{ questionAnswers[index].answer }}
                </div>
              </template>
            </v-textarea>
          </v-container>

          <v-container fluid v-if="question.question.type === 1">
            <v-radio-group>
              <v-radio
                  disabled
                  v-for="(alternative, i) in question.answer"
                  :key="i"
                  color="red"
                  :off-icon="getIcon(alternative, questionAnswers[index].answer)"
              >
                <template v-slot:label>
                  <h4 v-if="questionAnswers[index].answer.id === alternative.id && alternative.isCorrect" class="text-left" style="color: green">
                    {{ alternative.isCorrect ? `${ alternative.description } (Respuesta correcta)` : alternative.description }}
                  </h4>
                  <h4 v-if="questionAnswers[index].answer.id === alternative.id && !alternative.isCorrect" class="text-left" style="color: red">
                    {{ alternative.isCorrect ? `${ alternative.description } (Respuesta correcta)` : alternative.description }}
                  </h4>
                  <h4 v-if="questionAnswers[index].answer.id !== alternative.id" class="text-left" :style="{ color: alternative.isCorrect ? 'green' : '' }">
                    {{ alternative.isCorrect ? `${ alternative.description } (Respuesta correcta)` : alternative.description }}
                  </h4>
                </template>
              </v-radio>
            </v-radio-group>
          </v-container>

          <v-container fluid v-if="question.question.type === 2">
            <v-checkbox
                disabled
                v-for="(alternative, i) in question.answer"
                :key="i"
                :label="alternative.description"
                :value="alternative"
                :off-icon="getIconMultiple(alternative, questionAnswers[index])"
            >
             <template v-slot:label>
                  <h4 v-if="questionAnswers[index].answer.some(val => val.id === alternative.id ) && alternative.isCorrect" class="text-left" style="color: green">
                    {{ alternative.description }} (Respuesta Correcta)
                  </h4>
                  <h4 v-else-if="questionAnswers[index].answer.some(val => val.id === alternative.id ) && !alternative.isCorrect" class="text-left" style="color: red">
                    {{ alternative.description }} 
                  </h4>
                  <h4 v-else-if="questionAnswers[index].answer.find(val => val.id !== alternative.id )" class="text-left" :style="{ color: alternative.isCorrect ? 'green' : '' }">
                    {{ alternative.isCorrect ? `${ alternative.description } (Respuesta correcta)` : alternative.description }} 
                  </h4>
                </template>
            </v-checkbox>
          </v-container>

        </v-card>
      </v-tab-item>
      <v-tab-item value="tab-3">
        <v-card
          class="mt-4"
          width="100vw"
        >
        Guardadas
          <p v-for="(word, i) in savedWords" :key="i"> {{ word }}</p>
        </v-card>
        Destacadas
          <p v-for="(word, i) in highLight" :key="i"> {{ word }}</p>

      </v-tab-item>

    </v-tabs-items>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return{
      savedWords: [],
      highLight: [],
      reply: null,
      paragraphAnswer: null,
      questionAnswers: null,
      tab: null,
      paragraphs: [],
      questions: [],
      time: ''
    }
  },
  methods: {
    downloadReport() {
      let studentId = this.$route.params.idEstudiante;
      let activityId = this.$route.params.idActividad
      axios.get(`createPDF/${studentId}/${activityId}`)
    },
    paragraphHighLights(paragraph) {
      paragraph.replace('Chile', 'xd')
      // <strong style="background-color: yellow"> + word + </strong>

      // this.highLight.forEach(word => paragraph.replaceAll(word, 'iwiwiwiwi'));
      // paragraph.map(paragraph => {
      //   this.highLight.forEach(word => {
      //     if(paragraph)
      //   })
      // })
      console.log(paragraph)


      return paragraph
    },
    getIcon(alternative, answer) {
      if(alternative.id === answer.id && alternative.isCorrect) {
        return 'mdi-check-circle'
      }
      if(alternative.id === answer.id && !alternative.isCorrect) {
        return 'mdi-close-circle'
      }
      if(alternative.id !== answer.id) {
        return 'mdi-checkbox-blank-circle-outline'
      }
    },
    getIconMultiple(alternative, answer) {
      if(answer.answer.some(val => val.id === alternative.id) && alternative.isCorrect) {
        return 'mdi-checkbox-marked';
      }
      if(answer.answer.some(val => val.id === alternative.id) && !alternative.isCorrect) {
        return 'mdi-close-box';
      }
      if(answer.answer.some(val => val.id !== alternative.id)) {
        return 'mdi-checkbox-blank-outline';
      }
      

    },
    getColorAlternative(answer, alternative, correct) {
      if(!answer && correct) return 'red'
      if(answer.id === alternative && answer.isCorrect) {
        return 'green'
      }
      if(answer.id === alternative && !answer.isCorrect) {
        return 'red'
      }

    }
  },
  async created() {
    let textId;
    let idActivity = this.$route.params.idActividad;
    let idStudent = this.$route.params.idEstudiante;


    await axios.get('studentReplies_activity/' + idStudent + '/' + idActivity)
        .then(response => {
          const { reply } = response.data;
          this.reply = reply;
          this.savedWords = JSON.parse(reply.saveWord)
          this.paragraphAnswer = JSON.parse(reply.question);
          this.highLight = JSON.parse(reply.highLight);
          textId = this.paragraphAnswer[0].textId;
          this.questionAnswers = JSON.parse(reply.answer);
          this.time = reply.time;
        })

    await axios.get('text/' + textId).then(response => {
      const { paragraphs } = response.data;
      this.paragraphs = paragraphs;
    });

    await axios.get('activity/' + idActivity).then(response => {
      const { questions } = response.data;
      this.questions = questions;
    });
    this.paragraphHighLights(this.paragraphs[0].description)
  }
}
</script>