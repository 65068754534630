<template>
  <v-container>
    <p>Asignaturas</p>

    <div v-if="!loading && lessonsData.length === 0">
      <v-img
          width="60%"
          :src="require('@/assets/images/nodata.jpg')"
      ></v-img>
      <p>Actualmente no posee asignaturas creadas</p>
      <lesson-form class="mt-5"/>
    </div>

    <v-row v-if="!loading && lessonsData.length > 0">
      <v-autocomplete
          @change="toDetailsBar"
          :items="lessonsData"
          item-text="name"
          outlined
          dense
          label="Buscar Asignatura"
          return-object
      ></v-autocomplete>
    </v-row>

    <v-row v-if="!loading && lessonsData.length > 0" justify="center">
      <v-col v-for="(lesson, index) in showableArray" :key="index" xl="3" lg="4" md="4" sm="12">
        <card-component
            :id="lesson.id"
            :title="lesson.name"
            :description="lesson.description"
            image="lesson"
        >
          <template>
            <v-btn
                color="success"
                @click="toDetails(lesson.id)"
            >
              Ver Detalles
            </v-btn>
          </template>
        </card-component>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col>
        <v-progress-circular
            v-if="loading"
            size="200"
            :width="3"
            color="primary"
            indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>

    <v-container fluid v-if="!loading && lessonsData.length > 0">
      <v-btn
          v-for="(index, i) in paginate"
          :key="i"
          @click="current_page = index - 1"
          elevation="3"
          color="primary"
          fab
          style="margin-right: 10px"
      >
        {{ index }}
      </v-btn>
    </v-container>

  </v-container>
</template>

<script>

import CardComponent from "../../../components/UI/CardComponent";
import LessonForm from "../../../components/teacher/forms/LessonForm";

export default {

  data () {
    return {
      loading: null,
      items_per_page: 6,
      current_page: 0,
    }
  },

  components: { CardComponent ,LessonForm },
  computed: {
    lessonsData() {
      return this.$store.getters.getLessons;
    },
    paginate() {
      return  Math.ceil((this.lessonsData.length / this.items_per_page));
      // largo del arreglo / items por pagin - redondea para arriba
    },
    showableArray() {
      return this.lessonsData.slice(this.current_page*this.items_per_page, this.items_per_page + this.current_page*this.items_per_page);
    }
  },

  methods: {
    async getLessons() {
      this.loading = true;
      await this.$store.dispatch('lessons');
      this.loading = false;

    },
    toDetails(id) {
      this.$router.push('/profe/asignaturas/' + id);
    },
    toDetailsBar(lesson) {
      this.$router.push('/profe/asignaturas/' + lesson.id);
    }
  },
  created() {
    this.getLessons();
  }
}
</script>