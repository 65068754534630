<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col justify="center">
        <v-card>
          <v-form
              @submit.prevent="submit"
              ref="form"
              lazy-validation
          >
            <v-card-text>
              <v-select
                  @change="searchQuestions(form.text)"
                  v-model="form.text"
                  :items="texts"
                  item-text="title"
                  prepend-icon="mdi-format-title"
                  label="Seleccione un Texto"
                  required
                  return-object
              ></v-select>

              <date-component @date="form.limit = $event"/>

              <v-container fluid v-if="form.text && questions.length > 0">
                <p class="text-justify">Seleccione las preguntas que desea añadir:</p>
                <v-checkbox
                    v-for="(question, i) in questions" :key="i"
                    v-model="selected"
                    :label="question.title"
                    :value="question.id"
                ></v-checkbox>
              </v-container>
              <v-container v-if="questions.length === 0 && form.text">
                <p class="text-justify red--text">El texto no posee ninguna pregunta</p>
              </v-container>
              <v-textarea
                  prepend-icon="mdi-format-text-variant"
                  v-model="form.description"
                  auto-grow
                  filled
                  color="deep-purple"
                  label="Descripción"
                  rows="3"
              ></v-textarea>
            </v-card-text>

            <v-card-actions>
              <v-btn
                  v-if="isFormValid"
                  color="primary"
                  type="submit"
              >
                Crear Asignación
              </v-btn>
            </v-card-actions>
          </v-form>

        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DateComponent from "../../UI/DateComponent";
import axios from "axios";
import Swal from 'sweetalert2';
export default {
  props: {
    lessonId: {
      type: String
    }
  },
  components: {DateComponent},
  computed: {
    texts() {
      return this.$store.getters.getTexts;
    },
    questions() {
      return this.$store.getters.getQuestions;
    },
    isFormValid() {
      if (this.selected.length === 0 || !this.form.text || this.form.description === '' || !this.form.limit) {
        return false;
      }
      return true;
    }
  },
  data () {
    return {
      selected: [],
      form: {
        lessonId: parseInt(this.lessonId),
        text: null,
        limit: null,
        description: ''
      }
    }
  },
  methods: {
    async searchQuestions(text) {
      await this.$store.dispatch('questions', text.id)
      this.selected = [];
    },
    async getText() {
      await this.$store.dispatch('texts');
    },
    async submit() {
      await Swal.fire({
        title: '¿Desea crear la activad?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sí!',
        cancelButtonText: 'Volver'
      }).then( async (result) => {
        if (result.isConfirmed) {
          await Swal.fire(
              'Creada',
              'Activdad creada con éxito',
              'success'
          )
          let activityId;

          let data = {
            lessonId: this.form.lessonId,
            textId: this.form.text.id,
            date: this.form.limit,
            description: this.form.description
          }

          await axios.post('activity', data)
              .then( response => {
                const { id } = response.data.activity;
                activityId = id;
              })
          await axios.post('activity_question',{ activityId, questionId: this.selected })
          this.resetForm();
        }
      })

    },
    resetForm() {
      this.selected = [];
      this.form = {
        text: null,
        limit: null,
        description: ''
      }
    }
  },
  created() {
    this.getText();
  }
}
</script>