<template>
  <v-container class="main" fluid>
    <v-row justify="start"  style="height: 100vh">
      <v-col
          xl="2"
          lg="2"
          md="3"
          sm="4"
          xs="12"
      >
        <student-drawer/>
      </v-col>
      <v-col
          xl="10"
          lg="10"
          md="9"
          sm="8"
          xs="12"
          align="center"
      >
        <router-view/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StudentDrawer from "../../components/students/StudentDrawer";
export default {
  components: {StudentDrawer}
}
</script>