<template>
  <v-card
      class="mt-4"
      width="100vw"
      v-if="!loading"
  >
    <v-card-text>
      <p class="text-h4 text-justify">
        <strong>Autor: </strong>{{ textData.author }}
      </p>
      <p class="text-h4 text-justify">
        <strong>Título: </strong>{{ textData.title }}
      </p>
      <p v-if="textData.description !== ''" class="text-h5 text-justify">
        <strong>Descripción: </strong>{{ textData.description }}
      </p>
      <p class="text-h5 text-justify"><strong>Fecha Publicación: </strong>{{ textData.publicationYear }}</p>
      <p class="text-h5 text-justify"><strong>Tipo: </strong>{{ textData.type }}</p>
      <p class="text-h5 text-justify"><strong>Fuente: </strong>{{ textData.font }}</p>
      <div class="text--primary">
        <p v-for="(data,i) in textParagraphs" :key="i" class="text-justify" :style="{fontSize: textSize}" ref="target">
          <strong>Párrafo {{ i+1 }}: </strong>{{ data.description }}
        </p>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn
          text
          color="deep-purple accent-4"
      >
        Editar Texto
      </v-btn>
    </v-card-actions>
  </v-card>
  <v-row v-else justify="center">
    <v-col>
      <v-progress-circular

          size="200"
          :width="3"
          color="primary"
          indeterminate
      ></v-progress-circular>
    </v-col>
  </v-row>

</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      textData: [],
      textParagraphs: [],
      textSize: '17px',
      textId: null,
      loading: null
    }
  },
  methods: {
    async getText() {
      await axios.get('text/' + this.textId)
          .then( (response) => {
            const { text, paragraphs } = response.data;
            this.textData = text;
            this.textParagraphs = paragraphs;
          })
          .catch( error => console.log(error));
    },
  },
  async created() {
    this.loading = true;
    this.textId = this.$route.params.id;
    await this.getText();
    this.loading = false;
  }
}
</script>