export default {
    getToken(state) {
        return state.token;
    },
    getLessons(state) {
        return state.lessons;
    },
    getTexts(state) {
        return state.texts;
    },
    getUserInfo(state) {
        return state.userInfo;
    },
}