<template>
  <div>

    <v-tabs  v-model="tab">
      <v-tab href="#tab-1">Detalles Asignatura</v-tab>
      <v-tab href="#tab-2">Añadir Alumnos</v-tab>
      <v-tab href="#tab-3">Ver Listado Alumnos</v-tab>
      <v-tab href="#tab-4">Asignar Texto</v-tab>
      <v-tab href="#tab-5">Actividades Creadas</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <lesson-info/>
      </v-tab-item>

      <v-tab-item value="tab-2">
        <add-students/>
      </v-tab-item>

      <v-tab-item value="tab-3">
        <current-students/>
      </v-tab-item>

      <v-tab-item value="tab-4">
        <assignment-form :lessonId="this.$route.params.id"/>
      </v-tab-item>

      <v-tab-item value="tab-5">
        <lesson-activities/>
      </v-tab-item>

    </v-tabs-items>


  </div>
</template>

<script>
import CurrentStudents from "./details/CurrentStudents";
import AssignmentForm from "../../../components/teacher/forms/AssignmentForm";
import axios from "axios";
import AddStudents from "./details/AddStudents";
import LessonInfo from "./details/LessonInfo";
import LessonActivities from "./details/LessonActivities";

export default {
  components: {LessonInfo, AddStudents, CurrentStudents, AssignmentForm, LessonActivities},
  data () {
    return {
      lessonId: null,
      activities: [],
      tab: null,
    }
  },
  methods: {
    async getActivities() {

      await axios.get('activityTeacher/' + this.lessonId)
        .then(response => {
          const { activity } = response.data;
          this.activities = activity;
        })
    },
    async activityDetails(id) {
      await this.$router.push('/profe/grafico/' + id)
    },
  },
  async created() {
    this.lessonId = this.$route.params.id;
    await this.getActivities();
  }
}
</script>