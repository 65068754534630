<template>
  <v-row justify="center">
    <v-dialog
        persistent
        v-model="dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
        >
          Crear Pregunta
        </v-btn>
      </template>

      <v-container fluid>
        <v-row justify="center">
          <v-col justify="center">
            <v-card>
              <v-form
                  @submit.prevent="submit"
                  ref="form"
                  lazy-validation
              >
                <v-card-text>
                  <v-autocomplete
                      @change="changeType"
                      v-model="form.type"
                      :items="type"
                      item-text="name"
                      prepend-icon="mdi-format-text"
                      label="Tipo de Pregunta"
                      required
                      return-object
                  ></v-autocomplete>
                  <v-autocomplete
                      v-model="form.difficulty"
                      :items="difficulty"
                      item-text="name"
                      prepend-icon="mdi-numeric-1-circle-outline"
                      label="Dificultad"
                      required
                      return-object
                  ></v-autocomplete>
                  <v-text-field
                      v-model="form.title"
                      prepend-icon="mdi-format-title"
                      label="Título Pregunta"
                  ></v-text-field>
                  <v-text-field
                      type="number"
                      v-model="form.points"
                      prepend-icon="mdi-counter"
                      label="Puntaje"
                  ></v-text-field>
                  <v-textarea
                      prepend-icon="mdi-content-copy"
                      v-model="form.description"
                      auto-grow
                      filled
                      color="deep-purple"
                      label="Descripción(Opcional)"
                      rows="5"
                  ></v-textarea>
                  <v-text-field
                      @change="addAlternatives"
                      type="number"
                      v-model="alternativesNumber"
                      v-if="form.type && (form.type.id === 1 || form.type.id === 2)"
                      prepend-icon="mdi-format-title"
                      label="Cantidad de Alternativas"
                  ></v-text-field>

                  <p v-if="form.alternatives.length > 0" class="justify">
                    Selecciones la/s alternativa/s correcta/s haciendo click en el botón a la izquierda.
                  </p>

                  <v-row v-for="(_, key) in form.alternatives.length" :key="key">
                    <v-col cols="1" class="mt-4">
                      <v-btn
                          v-if="!form.alternatives[key].isCorrect"
                          @click="changeCorrectValue(key)"
                          color="#B71C1C"
                          dark
                          class="ml-4"
                      >
                        <v-icon
                            dark
                            fab
                        >
                          mdi-cancel
                        </v-icon>
                      </v-btn>

                      <v-btn
                          v-else
                          @click="changeCorrectValue(key)"
                          color="#43A047"
                          dark
                          class="ml-4"
                      >
                        <v-icon
                            dark
                            fab
                        >
                          mdi-check
                        </v-icon>
                      </v-btn>

                    </v-col>
                    <v-col cols="10">
                      <v-text-field
                          class="mr-10"
                          v-model="form.alternatives[key].description"
                          prepend-icon="mdi-format-cancel"
                          :label="`Alternativa ${ key + 1 }`"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-card-actions>
                  <v-btn
                      color="red"
                      text
                      @click="cancel"
                  >
                    Salir
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="primary"
                      text
                      type="submit"
                  >
                    Crear Pregunta
                  </v-btn>
                </v-card-actions>
              </v-form>

            </v-card>
          </v-col>

        </v-row>
      </v-container>
    </v-dialog>
    <div class="text-center ma-2">
      <v-snackbar
          :color="color"
          v-model="snackbar"
      >
        {{ text }}.

        <template v-slot:action="{ attrs }">
          <v-btn
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            OK
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-row>

</template>

<script>
export default {
  components: {},
  props: {
    textId: {
      type: Number,
    }
  },
  data () {
    return {
      snackbar: false,
      color: '',
      text: '',
      dialog: false,
      type: [{ id: 0, name: "Desarrollo"}, { id: 1, name: "Selección Múltiple (una respuesta correcta)"} , { id: 2, name: "Selección Múltiple (varias respuestas correctas)" }],
      difficulty: [{ id: 0, name: "Fácil" }, { id: 1, name: "Intermedio" }, { id: 2, name: "Difícil" }],
      alternativesNumber: null,
      form: {
        textId: null,
        title: '',
        difficulty: null,
        type: null,
        description: '',
        alternatives: [],
        points: null
      },
    }
  },
  methods: {
    changeType(type) {
      this.alternativesNumber = null;
      if(type.id === 0) {
        this.form.alternatives = []
      }
    },
    changeCorrectValue(key) {
      if ( this.form.type.id === 1 ) {
        this.form.alternatives.map( (data, id) => {
          if ( id === key ) return data.isCorrect = !data.isCorrect;
          if ( id !== key)  return data.isCorrect = false;
        });
      }

      if ( this.form.type.id === 2 ) {
        this.form.alternatives.map( (data, id) => {
          if ( id === key ) return data.isCorrect = !data.isCorrect;
        });
      }

    },
    addAlternatives() {
      this.form.alternatives = [];

      for ( let i = 0; i < this.alternativesNumber; i++) {
        this.form.alternatives.push({ description: '', isCorrect: false })
      }

    },

    setSnackBar(color, text, snack) {
      this.color = color;
      this.text = text;
      this.snackbar = snack
    },

    async resetForm() {
      this.form = {
        textId: null,
        title: '',
        difficulty: null,
        type: null,
        description: '',
        alternatives: [],
        points: null
      }
      this.alternativesNumber = null;
    },

    submit() {

      if(this.form.title==='' || this.form.difficulty===null || this.form.type===null ||  this.form.points===null) {
        this.setSnackBar('red', 'Faltan Datos', true);
        return
      }

      if ( this.form.type.id === 1 ) {

        if(this.form.alternatives.some(val => val.description === '')) {
          this.setSnackBar('red', 'Todas las preguntas deben tener descripción', true);
          return
        }
        if(!this.form.alternatives.some(val => val.isCorrect === true)) {
          this.setSnackBar('red', 'Debe existir al menos una respuesta correcta', true);
          return
        }
        if(this.form.alternatives.length < 2) {
          this.setSnackBar('red', 'Debe haber al menos dos alternativas', true);
          return
        }
      }
      if ( this.form.type.id === 2 ) {
        if(this.form.alternatives.some(val => val.description === '')) {
          this.setSnackBar('red', 'Todas las preguntas deben tener descripción', true);
          return
        }
        if(!this.form.alternatives.some(val => val.isCorrect === true)) {
          this.setSnackBar('red', 'Debe existir al menos una respuesta correcta', true);
          return
        }
        if(this.form.alternatives.length < 2) {
          this.setSnackBar('red', 'Debe haber al menos dos alternativas', true);
          return
        }
      }
      this.setSnackBar('green', 'Pregunta creada con éxito', true);

      this.form.textId = this.textId;
      this.$store.dispatch('createQuestion', this.form);
      this.$store.dispatch('questions', parseInt(this.textId));
      this.resetForm();

    },

    async cancel() {
      await this.resetForm();
      this.dialog = false;
    }
  },
}
</script>