<template>
  <v-container fluid>
    <v-row>
      <v-col v-for="(activity, i) in activities" :key="i">
        <card-component :description="activity.description">
          <template>
            <student-list :activityId="activity.id"/>
          </template>
          <template slot="details">
            <v-btn color="primary" @click="activityDetails(activity.id)">Detalle General</v-btn>
          </template>
        </card-component>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import CardComponent from "../../../../components/UI/CardComponent";
import StudentList from "../../../../components/teacher/StudentList";

export default {
  components: {StudentList, CardComponent},
  data () {
    return {
      lessonId: null,
      activities: [],
    }
  },
  methods: {
    async getActivities() {

      await axios.get('activityTeacher/' + this.lessonId)
          .then(response => {
            const { activity } = response.data;
            this.activities = activity;
          })
    },
    async activityDetails(id) {
      await this.$router.push('/profe/grafico/' + id)
    },
  },
  async created() {
    this.lessonId = this.$route.params.id;
    await this.getActivities();
  }
}
</script>