<template>
  <v-card
      fluid
      class="container--fluid"
      height="100%"
      width="100%"
  >
    <v-navigation-drawer
        permanent
        absolute
        dark
        src="https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg"
        width="100%"
        height="100%"
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              Rol
            </v-list-item-title>
            <v-list-item-subtitle>Profesor/a</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              {{ userInfo ? `${userInfo.name} ${userInfo.lastname} ${userInfo.secondLastName}` : '' }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ userInfo ? userInfo.email : '' }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-icon>
                <v-icon>mdi-school</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Asignaturas</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list>
            <v-list-item @click="changePage('asignaturas')">
              <v-btn
                  text
                  block
              >
                Ver Asignaturas
              </v-btn>
            </v-list-item>
            <v-list-item>
              <lesson-form :bottom-style="false"/>
            </v-list-item>
          </v-list>
        </v-menu>


        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-icon>
                <v-icon>mdi-signature-text </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Textos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list>
            <v-list-item @click="changePage('textos')">
              <v-btn
                  text
                  block
              >
                Ver Textos
              </v-btn>
            </v-list-item>
            <v-list-item>
              <text-form :bottom-style="false" />
            </v-list-item>
          </v-list>

        </v-menu>

        <v-menu offset-y v-if="userInfo && userInfo.id === 7">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
<!--              <v-list-item-icon>-->
<!--                <v-icon>mdi-human-male-board </v-icon>-->
<!--              </v-list-item-icon>-->
              <add-teacher :bottom-style="false"/>

            </v-list-item>
          </template>
<!--          <v-list>-->
<!--            <v-list-item @click="changePage('textos')">-->
<!--              <v-btn-->
<!--                  text-->
<!--                  block-->
<!--              >-->
<!--                Añadir Profesor-->
<!--              </v-btn>-->
<!--            </v-list-item>-->
<!--            <v-list-item>-->
<!--              <text-form :bottom-style="false" />-->
<!--            </v-list-item>-->
<!--          </v-list>-->

        </v-menu>

      </v-list>
      <v-row>
        <v-col class="mt-10" cols="12">
          <v-btn @click="logOut" block color="error">Cerrar Sesión</v-btn>
        </v-col>
      </v-row>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import LessonForm from "./forms/LessonForm";
import TextForm from "./forms/TextForm";
import AddTeacher from "./forms/AddTeacher";
export default {
  components: {
    AddTeacher,
    LessonForm,
    TextForm
  },
  computed: {
    userInfo() {
      return this.$store.getters.getUserInfo;
    }
  },
  methods: {
    async logOut() {
      await this.$store.dispatch('logOut');
      await this.$router.push('/login');
    },
    changePage(where) {
      if (where === 'mensajes' && this.$route.path !== '/profe/'+ where) {
        this.$router.push('/profe/' + where);
      }
      if (where === 'textos' && this.$route.path !== '/profe/' + where) {
        this.$router.push('/profe/' + where);
      }
      if (where === 'asignaturas' && this.$route.path !== '/profe/' + where) {
        this.$router.push('/profe/' + where);
      }
    }
  },
}
</script>