import axios from "axios";

export default {
    async createLesson(context, data) {

        const lessonData = {
            name: data.name,
            year: data.year,
            period: data.period,
            description: data.description,
            image: "ignsanhueza@udec.cl"
        };

        await axios.post('lesson', lessonData)

        await context.dispatch('lessons');
    },

    async createText(context, data) {
        const textData = {
            title: data.title,
            publicationYear: data.publicationYear,
            author: data.author,
            content: data.content,
            font: data.font,
            type: data.type,
            description: data.description,
            image: ''
        };
        await axios.post('text', textData);

        await context.dispatch('texts');
        // await context.dispatch('lessons');
    },

    async studentsInLesson(context, data) {

        const response = await axios.get('lesson_student/' + data);

        await context.commit('setStudentInLessons', response.data.students);
    },
    async createQuestion(context, data) {
        await axios.post('question', data);
    },
    async questions(context, data) {

        let response = await axios.get('questions/' + data);

        if ( !response.data.ok ) {
            const error = new Error( response.message || 'Failed auth');
            throw error;
        }
        await context.commit('setQuestions', response.data.questions);
    },
    async question(context, data) {
        let response = await axios.get('question/' + data);

        if ( !response.data.ok ) {
            const error = new Error( response.message || 'Failed auth');
            throw error;
        }
        await context.commit('setQuestion', response.data.question);
        await context.commit('setAnswers', response.data.answers);
    },
}