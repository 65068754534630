<template>
  <v-row justify="center">
    <v-dialog
        persistent
        v-model="dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            v-if="bottomStyle"
            color="primary"
            v-bind="attrs"
            v-on="on"
        >
          Crear Texto
        </v-btn>
        <v-btn
            v-else
            text
            block
            v-bind="attrs"
            v-on="on"
        >
          Crear Texto
        </v-btn>
      </template>

      <v-container fluid>
        <v-row justify="center">
          <v-col justify="center">
            <v-card>
              <v-form
                  @submit.prevent="submit"
                  ref="form"
                  lazy-validation
              >
                <v-card-text>
                  <v-text-field
                      v-model="form.title"
                      prepend-icon="mdi-format-title"
                      label="Titulo"
                  ></v-text-field>
                  <v-text-field
                      v-model="form.author"
                      prepend-icon="mdi-account-edit-outline"
                      label="Autor"
                  ></v-text-field>
                  <v-text-field
                      v-model="form.publicationYear"
                      prepend-icon="mdi-calendar"
                      label="Fecha Publicación"
                  ></v-text-field>
                  <v-text-field
                      v-model="form.font"
                      prepend-icon="mdi-alphabet-latin"
                      label="Fuente"
                  ></v-text-field>
                  <v-autocomplete
                      v-model="form.type"
                      :items="type"
                      prepend-icon="mdi-format-text-variant-outline"
                      label="Tipo de Texto"
                      required
                  ></v-autocomplete>
                  <v-text-field
                      @change="paragraphNumber"
                      type="number"
                      v-model="form.counter"
                      prepend-icon="mdi-counter"
                      label="Número de párrafos"
                  ></v-text-field>
                  <v-textarea
                      v-for="(data, i) in form.content.length"
                      :key="i"
                      prepend-icon="mdi-content-copy"
                      v-model="form.content[i]"
                      auto-grow
                      filled
                      color="deep-purple"
                      label="Contenido"
                      rows="5"
                  ></v-textarea>

                  <v-file-input
                      accept="image/jpg"
                      placeholder="Seleccione una Imágen"
                      prepend-icon="mdi-camera"
                      label="Imágen(Opcional)"
                  ></v-file-input>
                  <v-text-field
                      v-model="form.description"
                      prepend-icon="mdi-format-color-text"
                      label="Descripción"
                  ></v-text-field>
                </v-card-text>

                <v-card-actions>
                  <v-btn
                      color="red"
                      text
                      @click="cancel"
                  >
                    Salir
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="primary"
                      text
                      type="submit"
                  >
                    Crear Texto
                  </v-btn>
                </v-card-actions>
              </v-form>

            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <div class="text-center ma-2">
        <v-snackbar
            :color="color"
            v-model="snackbar"
        >
          {{ text }}.
          <template v-slot:action="{ attrs }">
            <v-btn
                text
                v-bind="attrs"
                @click="snackbar = false"
            >
              OK
            </v-btn>
          </template>
        </v-snackbar>
      </div>

    </v-dialog>
  </v-row>
</template>

<script>

export default {
  props: {
    bottomStyle: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      text: '',
      color: '',
      snackbar: false,
      dialog: false,
      type: ["Texto descriptivo", "Texto expositivo", "Texto científico", "Texto argumentativo", "Texto literario",
            "Texto publicitario", "Texto instructivo", "Texto histórico", "Texto jurídico", "Texto digital", "Texto periodístico"],
      form: {
        title: '',
        publicationYear: '',
        author: '',
        content: [],
        font: '',
        type: '',
        description: '',
      }
    }
  },
  methods: {
    paragraphNumber(e) {
      this.form.content = [];
      for (let i = 0; i < e; i ++) {
        this.form.content.push('');
      }
    },
    setSnackBar(color, text, snack) {
      this.color = color;
      this.text = text;
      this.snackbar = snack
    },
    resetForm() {
      this.form = {
        title: '',
        publicationYear: '',
        author: '',
        content: [],
        font: '',
        type: '',
        description: '',
      }
    },

    async submit() {
      if (this.form.title === '' || this.form.publicationYear.length === 0 ||
          this.form.author === '' || this.form.content.length === 0 ||
          this.form.font === '' || this.form.type === '' || this.form.description === '' || this.form.content.some(val => val.length === 0)) {
        this.setSnackBar('red', 'Debe rellenar todos los campos', true);
        return;
      }

      await this.$store.dispatch('createText', this.form)
          .then(() => {
            this.resetForm();
            this.setSnackBar('green', 'Texto creado con éxito', true);
          })
          .catch(() => this.setSnackBar('red', `Ya existe el texto con nombre : ${ this.form.title }`, true));

    },

    cancel() {
      this.resetForm();
      this.dialog = false;
    }
  }
}
</script>