export default {
    getStudentsInLesson(state) {
        return state.studentsInLesson;
    },
    getQuestions(state) {
        return state.questions;
    },
    getQuestion(state) {
        return state.question;
    },
    getAnswers(state) {
        return state.answers;
    },

}