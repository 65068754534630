import Vuex from 'vuex';
import Vue from 'vue';
import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import teachersModule from './teacher/index';
import studentsModule from './student/index';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        user: {
            state() {
                return{
                    token: localStorage.getItem('token') || sessionStorage.getItem('token') || null,
                    userInfo: null,
                    lessons: [],
                    texts: [],
                }
            },
            getters,
            actions,
            mutations,
            modules: {
                teachers: teachersModule,
                students: studentsModule
            }
        },

    },


})

export default store;