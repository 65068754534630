<template>
  <v-card
      elevation="2"
      class="mt-4"
      width="100vw"
      height="100vh"
  >
    <v-card-text>
      <v-text-field
          @change="edit=true"
          label="Nombre Asignatura"
          v-model="name"
      />
      <v-text-field
          @change="edit=true"
          label="Descripción"
          v-model="description"
      />
      <v-text-field
          @change="edit=true"
          type="number"
          label="Año"
          v-model="year"
      />
      <v-text-field
          @change="edit=true"
          label="Período"
          v-model="period"
      />
    </v-card-text>
    <v-card-actions>
      <v-btn
          :disabled="!edit"
          text
          color="deep-purple accent-4"
      >
        Guardar Cambios
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from "axios";

export default {
  data () {
    return {
      name: '',
      description: '',
      image: '',
      period: '',
      year: '',
      edit: false
    }
  },
  methods: {
    async getLesson() {
      let id = this.$route.params.id;
      await axios.get('lesson/' + id)
          .then((response) => {
            const { lessons } = response.data;
            this.lesson = lessons;
            this.name = lessons.name;
            this.description = lessons.description;
            this.image = lessons.image;
            this.period = lessons.period;
            this.year = lessons.year;
          })
          .catch(error => console.log(error));
    },
  },
  async created() {
    await this.getLesson();
  }
}
</script>